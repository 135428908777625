<template>
  <el-dialog
    id="EditBonusMatrixValueDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="496px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @opened="onShow"
    destroy-on-close
  >
    <el-form ref="EditBonusMatrixValueForm" :model="model" :rules="rules" @submit.native.prevent="onCommit" v-loading="loading">
      <el-row v-if="loading" justify="center" align="middle" type="flex" style="height:120px">
        <el-empty :image-size="70" :image="require('../../assets/icons/no_data.svg')"></el-empty>
      </el-row>
      <el-row v-else :gutter="8">
        <el-col :span="24">
          <div class="group-header">{{ $t("Одиниці вимірювання бонуса: Коефіцієнт") }}</div>
        </el-col>
        <el-col :span="11">
          <el-form-item prop="coeff" :label="$t('Новий коефіцієнт')">
            <el-input v-model="model.coeff" ref="coeff" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <div style="height:16px;"></div>
          <el-form-item>
            <el-button type="success" :disabled="!model.historyCount" @click="showHistory" size="medium">{{
              $t("Історія змін") + (model.historyCount ? " (" + $t("Версій") + ":" + model.historyCount + ")" : "")
            }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <BonusMatrixHistoryDialog bus-event="bonusMatrix.history" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";

export default {
  name: "EditBonusMatrixValue",
  components: { BonusMatrixHistoryDialog: () => import("@/components/forms/BonusMatrixHistory") },
  props: ["busEvent"],
  data() {
    var that = this;
    var defaultValidateRule = {};
    var digitalValueValidateRule = {
      validator: (rule, value, callback) => {
        // if (value == undefined || value == "") {
        //   callback();
        // } else
        if ((!that.isNumeric(value) && !that.isNumeric(value.split(",").join("."))) || value < 0) {
          callback(new Error("Поле має бути невід'ємним числом"));
        } else {
          callback();
        }
      },
    };
    return {
      loading: false,
      dialogShow: false,
      title: $tt("Редагування матриці бонусації"),
      operation: null,
      rules: {
        coeff: [digitalValueValidateRule],
      },
      model: {},
      initModel: {},
    };
  },

  methods: {
    onCommit() {
      this.$refs.EditBonusMatrixValueForm.validate((valid) => {
        if (valid) {
          this.$emit("commit", { operation: this.operation, model: this.prepareForCommit(this.model) });
        } else {
          this.$message.error($tt("Перевірте введені дані!"));
        }
        return false;
      });
    },
    onShow() {
      this.$refs.EditBonusMatrixValueForm.resetFields();
      this.model = _.extend({ coeff: 0.75 }, this.initModel);
      if (!this.model.coeff) this.model.coeff = 0.75;
      this.$nextTick(() => {
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.coeff.focus();
        });
      });
    },
    prepareForCommit(data) {
      data.coeff = data.coeff && !this.isNumeric(data.coeff) ? data.coeff.split(",").join(".") : data.coeff;
      return data;
    },
    nextFocus(next) {
      if (next == "commit") {
        this.onCommit();
      } else this.$refs[next].focus();
    },
    showHistory() {
      bus.$emit("bonusMatrix.history.show", {
        title: $tt("Історія змін"),
        initModel: this.initModel,
      });
    },
  },
  created() {
    bus.$on(this.busEvent + ".show", (data) => {
      this.loading = true;
      this.title = (data && data.title) || this.title;
      this.operation = (data && data.operation) || "create";
      this.initModel = _.extend({}, data ? data.initModel : {});
      this.dialogShow = true;
    });
    bus.$on(this.busEvent + ".hide", () => {
      this.dialogShow = false;
    });
  },
  computed: {},
};
</script>
<style lang="scss">
#EditBonusMatrixValueDialog {
  .el-dialog {
    max-height: 240px !important;
  }
  .el-button--medium {
    padding: 8px 20px;
  }
  .group-header {
    line-height: 24px;
    font-size: 17px;
    margin-bottom: 8px;
    font-weight: bold;
  }
}
</style>
